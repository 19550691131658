import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import {
  useHistory,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import NoteIcon from '@material-ui/icons/Note';
import { FaThermometerEmpty } from 'react-icons/fa';
import { RiLockPasswordFill } from 'react-icons/ri';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { IoTime } from 'react-icons/io5';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { authenticationService } from '../../services/authentication-service';
import Workers from '../workers/workers';
import Papers from '../papers/papers';
import Email from '../emailComponent/emailComponent';
import Reservations from '../reservations/reservations';
import SMS from '../SMS/sms';
import Tempeture from '../temperature/temperature';
import Register from '../register/register';
import UserTimer from '../userTImer/UserTimer';
import UserChangePassword from '../userChangePassword/userChangePassword';
import EditUser from '../editUser/EditUser';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: 'calc(100vh - 64px)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

const Dashboard = () => {
  const [user, setUser] = React.useState(null);
  const [logoutEvent, setLogoutEvent] = React.useState(false);
  const [ifAuthenticated, setIfAuthenticated] = React.useState(null);
  const [tmp, setTmp] = React.useState(null);
  React.useEffect(() => {
    if (authenticationService.currentUserValue) {
      setIfAuthenticated(authenticationService.currentUserValue);
      setUser(authenticationService.currentUserValue.user);
    }
  }, [authenticationService.currentUserValue]);

  React.useEffect(() => {
    if (ifAuthenticated) {
      setTmp(
        ifAuthenticated.user.role === 'admin' ? (
          <Workers />
        ) : (
          <UserTimer logoutEvent={logoutEvent} />
        )
      );
    }
  }, [ifAuthenticated]);
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleChangePage = (pageString = '') => {
    switch (pageString) {
      case 'Pracownicy':
        return setTmp(<Workers />);
      case 'czasPracyPracownika':
        return setTmp(<UserTimer logoutEvent={logoutEvent} />);
      case 'zmienHaslo':
        return setTmp(<UserChangePassword />);
      case 'nowyPracownik':
        return setTmp(<Register />);
      case 'edytujUżytkownika':
        return setTmp(<EditUser />);
      default:
        return <p>xD</p>;
    }
  };
  const logout = () => {
    authenticationService.logout();
    history.push('/');
    window.location.reload(true);
  };

  // eslint-disable-next-line react/prop-types
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) =>
        !!ifAuthenticated === true ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
  React.useEffect(() => {
    handleChangePage('');
  }, []);
  const handleLogout = () => {
    setLogoutEvent(true);
    logout();
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Router>
        {ifAuthenticated.user.role === 'admin' ? (
          <List>
            <ListItem
              button
              key="Pracownicy"
              onClick={() => handleChangePage('Pracownicy')}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Pracownicy" />
            </ListItem>
            <ListItem
              button
              key="Dodaj pracownika"
              onClick={() => handleChangePage('nowyPracownik')}
            >
              <ListItemIcon>
                <AiOutlineUserAdd style={{ height: '24px', width: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Dodaj pracownika" />
            </ListItem>
            <ListItem
              button
              key="Edytuj pracownika"
              onClick={() => handleChangePage('edytujUżytkownika')}
            >
              <ListItemIcon>
                <AccountBoxIcon style={{ height: '24px', width: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Edytuj/Usuń pracownika" />
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem
              button
              key="Sprawdź swój czas pracy"
              onClick={() => handleChangePage('czasPracyPracownika')}
            >
              <ListItemIcon>
                <IoTime style={{ height: '24px', width: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Sprawdź swój czas pracy" />
            </ListItem>
            <ListItem
              button
              key="Zmień hasło"
              onClick={() => handleChangePage('zmienHaslo')}
            >
              <ListItemIcon>
                <RiLockPasswordFill style={{ height: '24px', width: '24px' }} />
              </ListItemIcon>
              <ListItemText primary="Zmień hasło" />
            </ListItem>
          </List>
        )}
      </Router>
      <Divider />
    </div>
  );

  return (
    <>
      {user && (
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                onClick={toggleDrawer('left', true)}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={state.left}
                onClose={toggleDrawer('left', false)}
              >
                {ifAuthenticated && list('left')}
              </Drawer>
              <Typography variant="h6" className={classes.title}>
                Witaj użytkowniku {user.login}
              </Typography>
              <Button onClick={() => handleLogout()} color="inherit">
                Wyloguj się
              </Button>
            </Toolbar>
          </AppBar>
          <main style={{ width: '100%', height: '100%' }}>{tmp}</main>
        </div>
      )}
    </>
  );
};

export default Dashboard;
