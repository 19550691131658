import React from 'react';
import axios from 'axios';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import { authenticationService } from '../../services/authentication-service';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  },
}));

export default function Register() {
  const classes = useStyles();
  const [workers, setWorkers] = React.useState([]);
  const [displayedWorkers, setDisplayedWorkers] = React.useState([]);
  const [former, setFormer] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    render: false,
    text: null,
    alertType: null,
  });
  const [editedUser, setEditedUser] = React.useState(null);
  const prepareWorkersData = (workersData) => {
    const workersArray = [];
    workersData.forEach((worker, index) => {
      const eachWorker = {
        login: null,
        id: null,
        name: null,
        surname: null,
        lastSeen: '',
        workedHours: '',
        stake: null,
        uid: null,
      };
      eachWorker.id = index + 1;
      eachWorker.name = worker.name;
      eachWorker.uid = worker.uid;
      eachWorker.stake = worker.stake;
      eachWorker.login = worker.login;
      eachWorker.workedHours = worker.workedTime;
      eachWorker.surname = worker.surname;
      eachWorker.databaseId = worker.id;
      workersArray.push(eachWorker);
    });
    setWorkers(workersArray);
  };
  const getWorkers = () => {
    axios
      .get('http://squash-akademia-pracownicy.pl/workers/users', {
        headers: {
          authorization: `BEARER ${authenticationService.currentUserValue.token}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          prepareWorkersData(response.data);
        }
      });
  };

  const deleteWorker = (passedlogin) => {
    const body = {
      login: passedlogin.login,
    };
    axios
      .post('http://squash-akademia-pracownicy.pl/workers/deleteUser', body, {
        headers: {
          authorization: `BEARER ${authenticationService.currentUserValue.token}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAlertState({
            text: `Pomyślnie usunięto użytkownika ${passedlogin.login}`,
            render: true,
            alertType: 'success',
          });
          getWorkers();
        } else {
          setAlertState({
            text: `Ups coś poszło nie tak :( skontaktuj się z Marcinem`,
            render: true,
            alertType: 'error',
          });
        }
      });
  };

  React.useEffect(getWorkers, []);
  React.useEffect(() => {
    if (workers.length) {
      setDisplayedWorkers(workers);
    }
  }, [workers]);

  const handleEditClick = (params) => {
    setEditedUser(params.row);
  };

  React.useEffect(() => {
    if (editedUser) {
      document.getElementById('firstName').value = editedUser.name;
      document.getElementById('lastName').value = editedUser.surname;
      document.getElementById('stake').value = editedUser.stake;
    }
  }, [editedUser]);

  const editUser = (e) => {
    const name1 = e.target.firstName.value;
    const surname1 = e.target.lastName.value;
    const stake = e.target.stake.value;
    const body = {
      name: name1,
      surname: surname1,
      stake,
      uid: editedUser.uid,
    };
    axios
      .patch('http://squash-akademia-pracownicy.pl/workers/edit-user', body, {
        headers: {
          authorization: `BEARER ${authenticationService.currentUserValue.token}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        if (res.data.result) {
          setAlertState({
            text: `Pomyślnie zmieniono`,
            render: true,
            alertType: 'success',
          });
          getWorkers();
        } else {
          setAlertState({
            text: `Ups coś poszło nie tak :( skontaktuj się z Marcinem`,
            render: true,
            alertType: 'error',
          });
        }
      });
    e.preventDefault();
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 200px)',
        margin: '20px 40px',
        float: 'left',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ width: '40%', margin: '0 auto' }}>
        {displayedWorkers && (
          <DataGrid
            columns={[
              { field: 'id', width: 50 },
              {
                field: 'name',
                headerName: 'Imię',
                headerAlign: 'center',
                width: 150,
              },
              {
                field: 'surname',
                headerName: 'Nazwisko',
                headerAlign: 'center',
                width: 150,
              },
              {
                field: 'edit',
                headerAlign: 'center',
                headerName: 'Edytuj',
                width: 100,
                renderCell: (params) => (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      onClick={() => handleEditClick(params)}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Edytuj
                    </Button>
                  </div>
                ),
              },
              {
                field: 'delete',
                headerAlign: 'center',
                headerName: 'Usuń',
                width: 100,
                renderCell: (params) => (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      onClick={() => deleteWorker(params.row)}
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      Usuń
                    </Button>
                  </div>
                ),
              },
            ]}
            rows={displayedWorkers || 'ZARZ TU COŚ BĘDZIE'}
          />
        )}
      </div>

      <div style={{ width: '40%', height: '100%', margin: '0 auto' }}>
        {editedUser && (
          <div className={classes.paper}>
            <h2>{`Edytujesz użykownika - ${editedUser.name} ${editedUser.surname}`}</h2>
            <form onSubmit={editUser} className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    defaultValue={editedUser.name}
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="Imię"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={editedUser.surname}
                    id="lastName"
                    label="Nazwisko"
                    name="lastName"
                    autoComplete="lname"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="stake"
                    defaultValue={editedUser.stake}
                    label="stawka"
                    name="stake"
                    autoComplete="stake"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Edytuj pracownika
              </Button>
            </form>
          </div>
        )}
        {alertState.render && (
          <div className={classes.root}>
            <Alert
              severity={alertState.alertType}
              onClose={() => {
                setAlertState({ ...alertState, render: false });
              }}
            >
              {alertState.text}
            </Alert>
          </div>
        )}
      </div>
    </div>
  );
}
