import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { IoTimeOutline } from 'react-icons/io5';
import PropTypes from 'prop-types';
import { authenticationService } from '../../services/authentication-service';

const axios = require('axios');

const UserTimer = (props) => {
  const { logoutEvent } = props;
  const [startTime, setStartTime] = React.useState(null);
  const [endTime, setEndTime] = React.useState(null);
  const [currentTime, setCurrentTime] = React.useState(
    new Date().toTimeString().split(' ')[0]
  );

  const returnCurrentTime = (time, onlyTime = false) => {
    const currTime = new Date(time).toTimeString().split(' ')[0];
    setCurrentTime(currTime);
    if (onlyTime) {
      return <p>{currTime}</p>;
    }
  };

  const beginWork = () => {
    const body = {
      login: authenticationService.currentUserValue.user.login,
    };
    axios
      .post('http://squash-akademia-pracownicy.pl/workers/start-work', body, {
        headers: {
          authorization: `BEARER ${authenticationService.currentUserValue.token}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          localStorage.setItem('startTime', res.data.startTime);
        }
      });
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      returnCurrentTime(new Date().getTime());
    }, 1000);
  }, [new Date().getTime()]);

  const getTimeDifference = (lateDate, earlyDate) => {
    const lateDateLocal = lateDate.split('-');
    [lateDateLocal[0], lateDateLocal[1]] = [lateDateLocal[1], lateDateLocal[0]];
    lateDateLocal.join('/');
    const earlyDateLocal = earlyDate.split('-');
    [earlyDateLocal[0], earlyDateLocal[1]] = [
      earlyDateLocal[1],
      earlyDateLocal[0],
    ];
    lateDateLocal.join('/');
    return `${(
      (new Date(lateDateLocal).getTime() - new Date(earlyDateLocal).getTime()) /
      1000 /
      60 /
      60
    ).toFixed(2)} godziny`;
  };

  const endWork = () => {
    const body = {
      startTime: localStorage.getItem('startTime'),
      login: authenticationService.currentUserValue.user.login,
    };
    console.log(localStorage);
    if (localStorage.getItem('startTime')) {
      axios
        .post('http://squash-akademia-pracownicy.pl/workers/end-work', body, {
          headers: {
            authorization: `BEARER ${authenticationService.currentUserValue.token}`,
            'Access-Control-Allow-Origin': '*',
          },
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.removeItem('startTime');
          }
        });
    }
  };
  const returnCurrentDate = (time, setCase) => {
    const dayMonthYear = new Date(parseInt(time))
      .toLocaleDateString()
      .split('.')
      .join('-');
    const nowTime = new Date(parseInt(time)).toTimeString().split(' ')[0];
    if (setCase === 'start') {
      if (endTime !== null) setEndTime(null);
      localStorage.setItem('startTime', nowTime);
      setStartTime(`${dayMonthYear} ${nowTime}`);
      return `${dayMonthYear} ${nowTime}`;
    }
    if (setCase === 'end') {
      endWork(time);
      localStorage.removeItem('startTime');
      setEndTime(`${dayMonthYear} ${nowTime}`);
    }
  };
  React.useEffect(() => {
    returnCurrentDate(new Date().getTime(), 'end');
    setStartTime(null);
    setEndTime(null);
    localStorage.removeItem('startTime');
  }, [logoutEvent]);

  const beginWorkHandler = (time, caseTime) => {
    returnCurrentDate(time, caseTime);
    beginWork();
  };
  return (
    <div>
      <Grid
        style={{
          padding: '16px 16px 60px 16px',
          width: '40%',
          margin: '0 auto',
          borderBottom: '1px solid black',
        }}
        container
      >
        <Grid item xs={2} />
        <Grid item xs={8} style={{ marginTop: '40px', fontSize: '40px' }}>
          {currentTime}
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <div
        style={{
          width: '80%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '80px',
        }}
      >
        <Button
          onClick={() => beginWorkHandler(new Date().getTime(), 'start')}
          variant="contained"
          color="primary"
          disableElevation
          disabled={localStorage.getItem('startTime')}
        >
          Zacznij pracę!
        </Button>
        <Button
          onClick={() => returnCurrentDate(new Date().getTime(), 'end')}
          variant="contained"
          color="primary"
          disableElevation
          disabled={!localStorage.getItem('startTime')}
        >
          Zakończ pracę!
        </Button>
      </div>
      <Grid container style={{ marginTop: '60px' }}>
        <Grid item xs={2} />
        <Grid item xs={3}>
          {(localStorage.getItem('startTime') || startTime) &&
            `Prace rozpoczęto ${startTime}`}
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          {endTime && `Prace zakończono ${endTime}`}
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Grid style={{ padding: '16px' }} container>
        <Grid item xs={5} />
        <Grid item xs={2}>
          {startTime &&
            endTime &&
            `Przepracowano ${getTimeDifference(endTime, startTime)}`}
        </Grid>
        <Grid item xs={5} />
      </Grid>
    </div>
  );
};
UserTimer.propTypes = {
  logoutEvent: PropTypes.bool,
};
export default UserTimer;
