import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import { authenticationService } from '../../services/authentication-service';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkCreateNewAccount: {
    textAlign: 'right',
  },
  linkGetNewPassword: {
    textAlign: 'left',
  },
}));

const UserChangePassword = () => {
  const classes = useStyles();
  const lol = 'itsmeuserchangepassowrd';
  const changePassword = (event) => {
    const { login } = authenticationService.currentUserValue.user;
    const oldPass = document.getElementById('oldPass').value;
    const newPass = document.getElementById('newPass').value;
    const newPass2 = document.getElementById('newPass2').value;
    const passBody = {
      login,
      oldPass,
      newPass,
    };
    if (newPass2 !== newPass) {
      return alert('Hasła nie są takie same');
    }
    axios
      .post(
        'http://squash-akademia-pracownicy.pl/workers/change-password',
        passBody,
        {
          headers: {
            authorization: `BEARER ${authenticationService.currentUserValue.token}`,
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((res) => {
        if (res.data === 'hasło zostało zmienione') {
          alert(res.data.changed);
        } else {
          alert(res.data.changed);
        }
      });

    document.getElementById('oldPass').value = '';
    document.getElementById('newPass').value = '';
    document.getElementById('newPass2').value = '';
    event.preventDefault();
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="stare hasło"
                name="oldPass"
                variant="outlined"
                required
                fullWidth
                type="password"
                id="oldPass"
                label="Stare hasło"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="newPass"
                label="Nowe hasło"
                name="newPass"
                type="password"
                autoComplete="newPass"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="newPass2"
                type="password"
                label="Powtórz nowe hasło"
                id="newPass2"
                autoComplete="newPass2"
              />
            </Grid>
          </Grid>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={(event) => changePassword(event)}
            className={classes.submit}
          >
            Zmień
          </Button>
        </form>
      </div>
    </Container>
  );
};
export default UserChangePassword;
