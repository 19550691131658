import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { authenticationService } from '../../services/authentication-service';
import { history } from '../../helpers/history';

const axios = require('axios');

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Marcin Wieczorek
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkCreateNewAccount: {
    textAlign: 'right',
  },
  linkGetNewPassword: {
    textAlign: 'left',
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [tmp, setTmp] = React.useState(false);
  axios.defaults.headers.common = {
    'Content-Type': 'application/json',
  };

  const login = (event) => {
    const emailInput = username;
    const passwordInput = password;
    const user = authenticationService.login(emailInput, passwordInput);
    if (authenticationService.currentUserValue === false) {
      localStorage.clear();
    } else {
      console.log(authenticationService.currentUserValue);
    }
  };

  const handleSubmit = async (event) => {
    const emailInput = username;
    const passwordInput = password;
    authenticationService.login(emailInput, passwordInput).then(() => {
      history.push('/');
      window.location.reload(true);
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Zaloguj się
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Login"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            fullWidth
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            onClick={(event) => handleSubmit(event)}
            color="primary"
            className={classes.submit}
          >
            Zaloguj się
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
