import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import LoginForm from './components/login/loginForm';
import Dashboard from './components/dashboard/dashboard';
import { history } from './helpers/history';
import { authenticationService } from './services/authentication-service';

require('dotenv').config();
const axios = require('axios');

function App() {
  const [loginStatus, setLoginStatus] = React.useState(false);
  axios.defaults.headers.common = {
    'Content-Type': 'application/json',
  };
  const ifAuthenticated = authenticationService.currentUserValue;
  // eslint-disable-next-line react/prop-types
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(propss) =>
        !!ifAuthenticated === true ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...propss} />
        ) : (
          <LoginForm />
        )
      }
    />
  );
  return (
    <div className="App">
      <Router history={history}>
        <div className="container">
          <PrivateRoute exact path="/" component={Dashboard} />
        </div>
      </Router>
    </div>
  );
}

export default App;
