import React from 'react';
import axios from 'axios';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { authenticationService } from '../../services/authentication-service';
import EachWorkerTime from './eachWorkerTime';

const Workers = () => {
  const [workers, setWorkers] = React.useState([]);
  const [displayedWorkers, setDisplayedWorkers] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [login, setLogin] = React.useState(null);
  const prepareWorkersData = (workersData) => {
    const workersArray = [];
    workersData.forEach((worker, index) => {
      const eachWorker = {
        login: null,
        id: null,
        name: null,
        surname: null,
        lastSeen: '',
        workedHours: '',
        salary: '',
      };
      eachWorker.id = index + 1;
      eachWorker.name = worker.name;
      eachWorker.login = worker.login;
      eachWorker.workedHours = worker.workedTime
        ? (worker.workedTime / 1000 / 60 / 60).toFixed(2)
        : '';
      eachWorker.surname = worker.surname;
      eachWorker.stake = worker.stake;
      eachWorker.salary = `${(eachWorker.workedHours * worker.stake).toFixed(
        2
      )}zł`;
      workersArray.push(eachWorker);
    });
    setWorkers(workersArray);
  };
  const getWorkers = () => {
    axios
      .get('http://squash-akademia-pracownicy.pl/workers/users', {
        headers: {
          authorization: `BEARER ${authenticationService.currentUserValue.token}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          prepareWorkersData(response.data);
        }
      });
  };

  React.useEffect(getWorkers, []);
  React.useEffect(() => {
    if (workers.length) {
      setDisplayedWorkers(workers);
    }
  }, [workers]);
  React.useEffect(() => {
    if (displayedWorkers.length) {
      console.log(displayedWorkers);
    }
  }, [displayedWorkers]);

  const handleEditClick = (params) => {
    setOpen(true);
    setLogin(params.row.login);
  };

  return (
    <div
      style={{
        width: '90%',
        height: 'calc(100vh - 200px)',
        margin: '20px auto',
      }}
    >
      {displayedWorkers && (
        <DataGrid
          columns={[
            { field: 'id' },
            {
              field: 'name',
              headerName: 'Imię',
              headerAlign: 'center',
              width: 90,
            },
            {
              field: 'surname',
              headerName: 'Nazwisko',
              headerAlign: 'center',
              width: 120,
            },
            {
              field: 'lastSeen',
              headerName: 'Ostatnio widziany',
              headerAlign: 'center',
              width: 190,
            },
            {
              field: `workedHours`,
              headerName: 'Przepracowane godziny',
              headerAlign: 'center',
              width: 300,
            },
            {
              field: `salary`,
              headerName: 'Należna wypłata',
              headerAlign: 'center',
              width: 300,
            },
            {
              field: 'edit',
              headerAlign: 'center',
              headerName: 'Edytuj',
              width: 200,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={() => handleEditClick(params)}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Edytuj
                  </Button>
                </div>
              ),
            },
          ]}
          rows={displayedWorkers || 'ZARZ TU COŚ BĘDZIE'}
        />
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <EachWorkerTime
          login={login}
          close={setOpen}
          getWorkers={getWorkers}
          workers={workers}
        />
      </Modal>
    </div>
  );
};
export default Workers;
