/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Input from '@material-ui/core/Input';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'moment-timezone';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ModeCommentSharp } from '@material-ui/icons';
import { authenticationService } from '../../services/authentication-service';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const columns = [
  { id: 'login', label: 'Login', minWidth: 170 },
  { id: 'startTime', label: 'Początek pracy', minWidth: 200 },
  { id: 'endTime', label: 'Koniec pracy', minWidth: 200 },
  { id: 'workedTime', label: 'Przepracowany czas', minWidth: 200 },
  { id: 'save', label: 'zapisz' },
];

const EachWorkerTime = (props) => {
  const { login, close, getWorkers, workers } = props;
  const classes = useStyles();
  const [displayedData, setDisplayedData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const months = [
    {
      displayedValue: 'Styczeń',
      value: 'January',
    },
    {
      displayedValue: 'Luty',
      value: 'February',
    },
    {
      displayedValue: 'Marzec',
      value: 'March',
    },
    {
      displayedValue: 'Kwiecień',
      value: 'April',
    },
    {
      displayedValue: 'Maj',
      value: 'May',
    },
    {
      displayedValue: 'Czerwiec',
      value: 'June',
    },
    {
      displayedValue: 'Lipiec',
      value: 'July',
    },
    {
      displayedValue: 'Sierpień',
      value: 'August',
    },
    {
      displayedValue: 'Wrzesień',
      value: 'September',
    },
    {
      displayedValue: 'Październik',
      value: 'October',
    },
    {
      displayedValue: 'Listopad',
      value: 'November',
    },
    {
      displayedValue: 'Grudzień',
      value: 'December',
    },
  ];
  const [displayedMonth, setDisplayedMonth] = React.useState(
    months[new Date().getMonth()].value
  );
  const handleChangeMonth = (event) => {
    setDisplayedMonth(event.target.value);
  };
  const [page, setPage] = React.useState(0);
  const [startTimeFilter, setStartTimeFilter] = React.useState(0);
  const [stopTimeFilter, setSstopTimeFilter] = React.useState(0);
  const [salary, setSalary] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const filterRecords = (records) => {
    const localRecords = [...records];
    const ziom = localRecords.filter(
      (eachRecord) =>
        parseInt(eachRecord.startTime, 10) > parseInt(startTimeFilter, 10) &&
        parseInt(eachRecord.endTime, 10) < parseInt(stopTimeFilter, 10)
    );
    let workedTime = 0;
    ziom.map(
      (eachRecord) => (workedTime += parseInt(eachRecord.workedTime, 10))
    );
    const { stake } = workers.find((eachWorker) => eachWorker.login === login);
    setSalary(((workedTime / 3600000) * stake).toFixed(2));
    const newziom = ziom.map((record) => {
      record.startTime = parseInt(record.startTime);
      record.endTime = parseInt(record.endTime);
      record.workedTime = parseInt(record.workedTime);
      return record;
    });

    console.log('tenziom', newziom);
    return newziom;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getSingleUserData = () => {
    axios
      .get(`http://squash-akademia-pracownicy.pl/workers/boyTime${login}`, {
        headers: {
          authorization: `BEARER ${authenticationService.currentUserValue.token}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log('staed', filterRecords(response.data).length);
          setDisplayedData(filterRecords(response.data));
        }
      });
  };

  React.useEffect(() => {
    const startOfMonth = new Date(
      moment().month(displayedMonth).startOf('month').toDate()
    ).getTime();
    const endOfMonth = new Date(
      moment().month(displayedMonth).endOf('month').toDate()
    ).getTime();
    setStartTimeFilter(startOfMonth);
    setSstopTimeFilter(endOfMonth);
  }, [displayedMonth]);

  React.useEffect(() => {
    if (startTimeFilter && stopTimeFilter) getSingleUserData();
  }, [startTimeFilter, stopTimeFilter]);
  const handleSaveRow = (row) => {
    axios
      .patch(
        'http://squash-akademia-pracownicy.pl/workers/updateUserWorkingTime',
        row,
        {
          headers: {
            authorization: `BEARER ${authenticationService.currentUserValue.token}`,
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((res) => {
        if (res.data.result) {
          getSingleUserData();
          getWorkers();
        }
      });
  };

  const handleChangeValueCell = (e, row, index) => {
    const tmp = [...displayedData];
    let valueToChange = '';
    if (index === 1) {
      valueToChange = 'startTime';
    }
    if (index === 2) {
      valueToChange = 'endTime';
    }
    tmp.forEach((eachData, indexLoop) => {
      if (eachData.id === row.id) {
        if (
          valueToChange === 'startTime' &&
          new Date(e.target.value).getTime() > row.endTime
        ) {
          return alert(
            'Czas rozpoczęcia nie może być większy od zakończenia pracy'
          );
        }
        if (
          valueToChange === 'endTime' &&
          new Date(e.target.value).getTime() < row.startTime
        ) {
          return alert(
            'Czas zakończenia nie może być mniejszy od rozpoczęcia pracy'
          );
        }
        tmp[indexLoop][valueToChange] = new Date(e.target.value).getTime();
        setDisplayedData(tmp);
      }
    });
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 'calc(100% - 200px)',
          height: 'calc(100vh - 200px)',
          margin: '0 auto',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '40px',
            backgroundColor: '#3f51b5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            color: 'white',
          }}
        >
          <span>{`Edycja użytkownika ${login}`}</span>
          <span
            style={{
              display: 'block',
              position: 'absolute',
              top: '8px',
              right: '10px',
              cursor: 'pointer',
            }}
            role="button"
            onClick={() => close(false)}
          >
            <HighlightOffIcon />
          </span>
        </div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {index === 4 && (
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                type="button"
                                onClick={() => handleSaveRow(row)}
                              >
                                Zapisz
                              </Button>
                            )}
                            {index === 0 && value}
                            {index === 3 &&
                              `${(value / 1000 / 60).toFixed(0)}min.`}
                            {(index === 1 || index === 2) && (
                              <TextField
                                type="datetime-local"
                                width={200}
                                value={
                                  value &&
                                  new Date(
                                    value +
                                      new Date().getTimezoneOffset() *
                                        -60 *
                                        1000
                                  )
                                    .toISOString()
                                    .split('.')[0]
                                }
                                onChange={(e) =>
                                  handleChangeValueCell(e, row, index)
                                }
                              />
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Miesiąc</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={displayedMonth}
                onChange={handleChangeMonth}
              >
                {months.map((eachMonth) => (
                  <MenuItem value={eachMonth.value}>
                    {eachMonth.displayedValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p id="demo-simple-select-label">
              Należna wypłata za miesiąc to: {salary} zł.
            </p>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={displayedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
};

EachWorkerTime.propTypes = {
  login: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  getWorkers: PropTypes.func.isRequired,
  workers: PropTypes.array.isRequired,
};
export default EachWorkerTime;
